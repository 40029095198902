import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ProductSliderV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'

    return<div className="ltn__search-by-place-area before-bg-top bg-image-top--- pt-115 pb-70" data-bs-bg={publicUrl+"assets/img/bg/20.jpg"}>
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center---">
			          <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">what we do</h6>
			          <h1 className="section-title">Explore Our Services </h1>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__search-by-place-slider-1-active slick-arrow-1 go-top">
			      <div className="col-lg-4">
			        <div className="ltn__search-by-place-item">
			          <div className="search-by-place-img">
			            <Link to="/False-ceiling-light-partition-installation"><img src={publicUrl+"assets/img/product-3/1.jpg"} alt="#" /></Link>
			            <div className="search-by-place-badge">
			              <ul>
			                
			              </ul>
			            </div>
			          </div>
			          <div className="search-by-place-info">
			            
			            <h4><Link to="#">False ceiling & light partition installation</Link></h4>
			            <div className="search-by-place-btn">
			              <Link to="/False-ceiling-light-partition-installation">For more details <i className="flaticon-right-arrow" /></Link>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__search-by-place-item">
			          <div className="search-by-place-img">
			            <Link to="#"><img src={publicUrl+"assets/img/product-3/2.jpg"} alt="#" /></Link>
			            <div className="search-by-place-badge">
			              <ul>
			               
			              </ul>
			            </div>
			          </div>
			          <div className="search-by-place-info">
			            
			            <h4><Link to="#">Sanitary installation & pipes repairing</Link></h4>
			            <div className="search-by-place-btn">
			              <Link to="/Sanitary-installation-pipes-repairing">For more details <i className="flaticon-right-arrow" /></Link>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__search-by-place-item">
			          <div className="search-by-place-img">
			            <Link to="#"><img src={publicUrl+"assets/img/product-3/3.jpg"} alt="#" /></Link>
			            <div className="search-by-place-badge">
			              <ul>
			                
			              </ul>
			            </div>
			          </div>
			          <div className="search-by-place-info">
			            
			            <h4><Link to="#">Facilities management</Link></h4>
			            <div className="search-by-place-btn">
			              <Link to="/Facilities-management">For more details <i className="flaticon-right-arrow" /></Link>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__search-by-place-item">
			          <div className="search-by-place-img">
			            <Link to="#"><img src={publicUrl+"assets/img/product-3/4.jpg"} alt="#" /></Link>
			            <div className="search-by-place-badge">
			              <ul>
			               
			              </ul>
			            </div>
			          </div>
			          <div className="search-by-place-info">
			            
			            <h4><Link to="#">Floor & wall tiling works</Link></h4>
			            <div className="search-by-place-btn">
			              <Link to="/Floor-wall-tiling-works">For more details <i className="flaticon-right-arrow" /></Link>
			            </div>
			          </div>
			        </div>
			      </div>
				  <div className="col-lg-4">
			        <div className="ltn__search-by-place-item">
			          <div className="search-by-place-img">
			            <Link to="#"><img src={publicUrl+"assets/img/product-3/5.jpg"} alt="#" /></Link>
			            <div className="search-by-place-badge">
			              <ul>
			               
			              </ul>
			            </div>
			          </div>
			          <div className="search-by-place-info">
			            
			            <h4><Link to="#">Engraving & ornamentation works</Link></h4>
			            <div className="search-by-place-btn">
			              <Link to="/Engraving-ornamentation-works">For more details <i className="flaticon-right-arrow" /></Link>
			            </div>
			          </div>
			        </div>
			      </div>
				  <div className="col-lg-4">
			        <div className="ltn__search-by-place-item">
			          <div className="search-by-place-img">
			            <Link to="#"><img src={publicUrl+"assets/img/product-3/6.jpg"} alt="#" /></Link>
			            <div className="search-by-place-badge">
			              <ul>
			               
			              </ul>
			            </div>
			          </div>
			          <div className="search-by-place-info">
			            
			            <h4><Link to="#">Painting & Contracting</Link></h4>
			            <div className="search-by-place-btn">
			              <Link to="/Painting-contracting">For more details <i className="flaticon-right-arrow" /></Link>
			            </div>
			          </div>
			        </div>
			      </div>
				  <div className="col-lg-4">
			        <div className="ltn__search-by-place-item">
			          <div className="search-by-place-img">
			            <Link to="#"><img src={publicUrl+"assets/img/product-3/7.jpg"} alt="#" /></Link>
			            <div className="search-by-place-badge">
			              <ul>
			               
			              </ul>
			            </div>
			          </div>
			          <div className="search-by-place-info">
			            
			            <h4><Link to="#">Plaster Works</Link></h4>
			            <div className="search-by-place-btn">
			              <Link to="/Plaster-works">For more details <i className="flaticon-right-arrow" /></Link>
			            </div>
			          </div>
			        </div>
			      </div>
				  <div className="col-lg-4">
			        <div className="ltn__search-by-place-item">
			          <div className="search-by-place-img">
			            <Link to="#"><img src={publicUrl+"assets/img/product-3/8.jpg"} alt="#" /></Link>
			            <div className="search-by-place-badge">
			              <ul>
			               
			              </ul>
			            </div>
			          </div>
			          <div className="search-by-place-info">
			            
			            <h4><Link to="#">Glass & aluminum installation & maintenance</Link></h4>
			            <div className="search-by-place-btn">
			              <Link to="/Glass-aluminum-installation">For more details <i className="flaticon-right-arrow" /></Link>
			            </div>
			          </div>
			        </div>
			      </div>
				  <div className="col-lg-4">
			        <div className="ltn__search-by-place-item">
			          <div className="search-by-place-img">
			            <Link to="#"><img src={publicUrl+"assets/img/product-3/9.jpg"} alt="#" /></Link>
			            <div className="search-by-place-badge">
			              <ul>
			               
			              </ul>
			            </div>
			          </div>
			          <div className="search-by-place-info">
			            
			            <h4><Link to="#">Electrical fittings & fixtures repairing</Link></h4>
			            <div className="search-by-place-btn">
			              <Link to="/Electrical-fittings">For more details <i className="flaticon-right-arrow" /></Link>
			            </div>
			          </div>
			        </div>
			      </div>
				  <div className="col-lg-4">
			        <div className="ltn__search-by-place-item">
			          <div className="search-by-place-img">
			            <Link to="#"><img src={publicUrl+"assets/img/product-3/10.jpg"} alt="#" /></Link>
			            <div className="search-by-place-badge">
			              <ul>
			               
			              </ul>
			            </div>
			          </div>
			          <div className="search-by-place-info">
			            
			            <h4><Link to="#">Wallpaper Fixing Works</Link></h4>
			            <div className="search-by-place-btn">
			              <Link to="/Wallpaper-fixing-works">For more details <i className="flaticon-right-arrow" /></Link>
			            </div>
			          </div>
			        </div>
			      </div>
				  <div className="col-lg-4">
			        <div className="ltn__search-by-place-item">
			          <div className="search-by-place-img">
			            <Link to="#"><img src={publicUrl+"assets/img/product-3/11.jpg"} alt="#" /></Link>
			            <div className="search-by-place-badge">
			              <ul>
			               
			              </ul>
			            </div>
			          </div>
			          <div className="search-by-place-info">
			            
			            <h4><Link to="#">Carpentry & wood flooring works</Link></h4>
			            <div className="search-by-place-btn">
			              <Link to="/Carpentry-wood-flooring-works">For more details <i className="flaticon-right-arrow" /></Link>
			            </div>
			          </div>
			        </div>
			      </div>
				 



			      {/*  */}
			    </div>
			  </div>
			</div>

}
}
export default ProductSliderV2