import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV3 extends Component {
	render() {
	  let publicUrl = process.env.PUBLIC_URL + "/";
	  return (
		<>
		  <div className="ltn__about-us-area pt-115 pb-100">
			<div className="container">
			  <div className="row">
				<div className="col-lg-6 align-self-center">
				  <div className="about-us-img-wrap about-img-left">
					<img
					  src={publicUrl + "assets/img/others/11.png"}
					  alt="About Us Image"
					/>
					<div className="about-us-img-info about-us-img-info-2 about-us-img-info-3 d-none">
					  <div className="ltn__video-img ltn__animation-pulse1">
						<img
						  src={publicUrl + "assets/img/others/8.png"}
						  alt="video popup bg image"
						/>
						<a
						  className="ltn__video-icon-2 ltn__video-icon-2-border---"
						  href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0"
						  data-rel="lightcase:myCollection"
						>
						  <i className="fa fa-play" />
						</a>
					  </div>
					</div>
				  </div>
				</div>
				<div className="col-lg-6 align-self-center">
				  <div className="about-us-info-wrap">
					<div className="section-title-area ltn__section-title-2--- mb-30">
					  <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
						About Us
					  </h6>
					  <h1 className="section-title">
						Dream Living Spaces Setting New Build
					  </h1>
					  <p>
						Welcome to Flying Dragon Technical Services L.L.C, a
						pinnacle of precision and expertise in the technical
						services landscape. With a comprehensive suite of
						offerings ranging from meticulous false ceiling
						installations, sanitary fittings, and facilities
						management to artistic engraving, painting, and carpentry
						works, we pride ourselves on delivering excellence at
						every juncture.
					  </p>
					</div>
					<div className="ltn__feature-item ltn__feature-item-3">
					  <div className="ltn__feature-icon">
						<span>
						  <i className="flaticon-house-4" />
						</span>
					  </div>
					  <div className="ltn__feature-info">
						<h4>
						  <a href="service-details.html">Expert Team</a>
						</h4>
						<p>
						  Our expert team stands at the forefront of technical
						  services, combining industry knowledge with innovative
						  solutions.
						</p>
					  </div>
					</div>
					<div className="ltn__feature-item ltn__feature-item-3">
					  <div className="ltn__feature-icon">
						<span>
						  <i className="flaticon-call-center-agent" />
						</span>
					  </div>
					  <div className="ltn__feature-info">
						<h4>
						  <a href="service-details.html">Reasonable Cost</a>
						</h4>
						<p>
						  At the intersection of quality and affordability, our
						  technical services prioritize delivering exceptional
						  value without compromising on excellence
						</p>
					  </div>
					</div>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
  
		  <div className="ltn__about-us-area pt-115 pb-100 bg-light">
			<div className="container">
			  <div className="row">
				<div className="col-lg-6 align-self-center">
				  <div className="about-us-info-wrap">
					<div className="section-title-area ltn__section-title-2--- mb-30">
					  <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
						INTERIOR DESIGNING
					  </h6>
					  <h1 className="section-title">
					  Transform Your Space with Luxury Interior Design
					  </h1>
					  <p>
						we specialize in transforming spaces with exceptional
						interior design, offering everything from luxurious villa
						interiors and exteriors to stunning modern kitchen
						cabinets and display shelves. Our expertise extends to
						designing sophisticated ladies salons and spas, sleek and
						functional office spaces, and creating spacious,
						comfortable bedroom and living room layouts. We also craft
						elegant, high-end bathroom designs, ensuring every corner
						of your home exudes style, comfort, and luxury. Explore
						our diverse portfolio and discover how we bring your
						vision to life.
					  </p>
					</div>
				 
					
				  </div>
				</div>
				<div className="col-lg-6 align-self-center">
				  <div className="about-us-img-wrap about-img-left">
					<img
					  src={publicUrl + "assets/img/others/flying-dragon.webp"}
					  alt="About Us Image"
					/>
					<div className="about-us-img-info about-us-img-info-2 about-us-img-info-3 d-none">
					  <div className="ltn__video-img ltn__animation-pulse1">
						<img
						  src={publicUrl + "assets/img/others/flying-dragon.webp"}
						  alt="video popup bg image"
						/>
						<a
						  className="ltn__video-icon-2 ltn__video-icon-2-border---"
						  href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0"
						  data-rel="lightcase:myCollection"
						>
						  <i className="fa fa-play" />
						</a>
					  </div>
					</div>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		</>
	  );
	}
  }

export default AboutV3