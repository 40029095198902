import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class VideoV2 extends Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/';
        let imagealt = 'image'; // This is not being used, remove it if unnecessary

        return (
            <>
                <div className="ltn__about-us-area section-bg-1--- bg-image-right-before pt-120 pb-90">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 align-self-center">
                                <div className="about-us-info-wrap">
                                    <div className="section-title-area ltn__section-title-2--- mb-20">
                                        <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">Building Facilities</h6>
                                        <h1 className="section-title">Why Choose Us</h1>
                                        <p>By choosing Flying Dragon Technical Services LLC, you are partnering with a trusted provider committed to delivering exceptional technical services tailored to meet your specific needs, ensuring satisfaction, and peace of mind.</p>
                                    </div>
                                    <ul className="ltn__list-item-half ltn__list-item-half-2 list-item-margin clearfix">
                                        <li><i className="icon-done" /> Comprehensive Services</li>
                                        <li><i className="icon-done" /> Expertise & Experience</li>
                                        <li><i className="icon-done" /> Quality Assurance</li>
                                        <li><i className="icon-done" /> Customized Solutions</li>
                                        <li><i className="icon-done" /> Timely Execution</li>
                                        <li><i className="icon-done" /> Innovative Solutions</li>
                                    </ul>
                                    <div className="ltn__animation-pulse2 text-center mt-30">
                                        <a className="ltn__video-play-btn bg-white--- ltn__secondary-bg" href="tel:+971588893188" data-rel="lightcase">
                                            <i className="fa fa-phone ltn__secondary-color--- white-color" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 align-self-center">
                                <div className="about-us-img-wrap about-img-left">
                                    {/* You can add an image or video here if needed */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default VideoV2;
